import './css/bootstrap/bootstrap.min.css';
import React from 'react';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className="container">
      <Header />

      <div className="bg-paper-2 bd-cheatsheet container-fluid d-flex justify-content-center align-items-center">
        <section id="content">
          <div className="container">
            <div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
}

export default App;
