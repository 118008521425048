// src/Kaki.js
import React, { useState, useEffect } from 'react';


function Footer() {
    const [date, setDate] = useState(new Date());
    const formattedDate = date.toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    useEffect(() => {
        const interval = setInterval(() => {
        setDate(new Date());
    }, 1000);

    return () => {
        clearInterval(interval);
    };
    }, [date]);

    return (
        <footer className="footer fixed-bottom bg-light d-flex align-items-stretch">
            <div className="container-fluid d-flex align-items-center">&copy; 2016 - {date.getFullYear()}, Chandra.co.id</div>
            <div className="container-fluid d-flex justify-content-end flex-column">
                <div className="ml-auto">{date.toLocaleTimeString('en-US', { hour12: false })}</div>
                <div className="ml-auto">{formattedDate}</div>
            </div>
        </footer>
    );
}

export default Footer;

