// src/Kepala.js
import React from 'react';
import Logo from './../../img/Logo.png';
import HutRI from './../../img/HUT_RI_78_Logo.png'

function Header() {
    return (
        <header className='fixed-top bd-header bg-light hut-ri d-flex align-items-stretch border-bottom border-dark'>
            <div className="container-fluid d-flex align-items-center">
                <img src={Logo} width={150} alt="chandra personal website"/>
            </div>
            <div className="container-fluid d-flex justify-content-end">
                <img src={HutRI} height={100} alt="hut ri ke 77"/>
            </div>
        </header>
    );
}

export default Header;

